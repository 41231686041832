import React, { useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { getStreakCountAction } from '../../redux/actions';
import { Container, Col, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
const StreakActivity = props => {
  const { t } = useTranslation();
  const activityData = props.activityData;
  const [pageRedirect, setPageRedirect] = useState(false);
  let { id } = useParams();
  const [daysCount, setDaysCount] = useState(false);
  const onSubmit = event => {
    getStreakCountAction(id)
      .then(responseData => {
        if (responseData?.httpCode === '201') {
          toast.success(`${t('Congratulations')}! ${t('You have earned')} ${activityData.points} ${t('Points')}`);
        }
        setDaysCount(responseData?.data?.daysCount || 0);
      })
      .catch(err => {
        // if (watchStatus.playbackevtid === 'none') {
        //   //toast.error(`${t('Sorry')}! ${t("You've earned the maximum points possible for this action")}`);
        // } else {
        //   toast.error(t('Something went wrong'));
        // }
        console.log('Response Error:', JSON.stringify(err));
        //setPageRedirect(true);
      });
  };

  if (pageRedirect) {
    return <Redirect to="/" />;
  } else {
    return (
      <>
        <div>
          <div className="page-heading smoke-bg">
            <div className="overlay-mask">
              <h1 className="bold">Login and Earn</h1>
            </div>
          </div>
        </div>
        <div className="portal-point-list-container">
          <Container>
            <Row className="justify-content-center my-5">
              <Col md={11} xs={12} sm={12}>
                <img src="/public/activities/streak-banner.png" width="100%" />
              </Col>
              <Col md={11} xs={12} sm={12} className="mt-5">
                <h1 className="text-left">{activityData?.title}</h1>
                <p>{activityData?.description}</p>
              </Col>
              <Col className="text-center" md={11} xs={12} sm={12}>
                {daysCount === false ? (
                  <button type="button" onClick={onSubmit} className="btn btn-primary btn-submit" id="Streak-code-btn">
                    <Trans>Check Streak Count</Trans>
                  </button>
                ) : (
                  <span>
                    Your streak count is: <b>{daysCount}</b>
                  </span>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
};
export default StreakActivity;
