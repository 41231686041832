import React, { useState } from "react";
import { logoutAction } from "../redux/actions";
import { connect } from "react-redux";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import IsLoadingHOC from "./IsLoadingHOC";

const Logout = (props) => {
	const handleLogout = () => {
		props.setLoading(true);
		props
			.logoutAction()
			.then((response) => {
				props.setLoading(false);
				window.location.href = "/";
			})
			.catch((ex) => {
				console.log("Error In API Call", ex);
				props.setLoading(false);
				window.location.href = "/";
			});
	};
	return (
		
		<Link id="button-logout" onClick={handleLogout}>
			<span className="mat-icon material-icons">exit_to_app</span> <Trans>Logout</Trans>
		</Link>
	);
};
export default connect(null, { logoutAction })(IsLoadingHOC(Logout, "Loading...."));
