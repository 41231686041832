import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Container, Row } from 'react-bootstrap';
import IsLoadingHOC from '../../components/IsLoadingHOC';
import { connect, useDispatch } from 'react-redux';
import { pointsUpdate, surveyDetail, userSurveyFeedback, getCompletedActivity, balanceGetAction } from '../../redux/actions';
import { TextField, createMuiTheme, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormGroup, Checkbox } from '@material-ui/core';
import EarnPointList from './EarnPointList';
import Required from '../Required';
import { toast } from 'react-toastify';
const formLabelsTheme = createMuiTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: '#db3131',
        '&$error': {
          color: '#db3131',
        },
      },
    },
  },
});

const QuizQuestions = props => {
  const activityData = props.activityData;
  const setLoading = props.setLoading;
  const history = useHistory();
  let { id } = useParams();
  const [surveyStatus, setSurveyStatus] = useState({ status: false, activityEventId: 'none' });
  const [isPointsApiCalled, setIsPointsApiCalled] = useState(false)
  const [questions, setQuestions] = useState([]);
  const dispatch = useDispatch()
  useEffect(() => {
    setLoading(true);
    let data = {
      reference: id,
      state: 'started',
    };
    props
      .surveyDetail(activityData.data)
      .then(res => {
        const questions = [...res.data.questions].map(e => {
          return {
            ...e,
            selected: [],
            isRequired: e.isRequired ? e.isRequired : false,
            error: false,
          };
        });
        setQuestions(questions);
        setLoading(false);
      })
      .catch(err => {
        console.log('Response Error:', JSON.stringify(err));
        setSurveyStatus({ status: false, activityEventId: 'none' });
        setLoading(false);
      });

    // eslint-disable-next-line
  }, []);

  const quizStart = async () => {
    if (isPointsApiCalled) {
      return false;
    }
    setIsPointsApiCalled(true)
    let data = {
      reference: id,
      state: 'started',
    };
    props
      .pointsUpdate(data, 'post')
      .then(responseData => {
        setSurveyStatus({ ...surveyStatus, status: true, activityEventId: responseData.data.id });
      })
      .catch(err => {
        console.log('Response Error:', JSON.stringify(err));
        setSurveyStatus({ ...surveyStatus, status: false });
      });
  };
  const handleAnswers = async (e, text) => {
    e.persist();
    quizStart();
    let [questionId, feedback] = text === 'text' ? [e.target.id, e.target.value] : e.target.value.split('-');
    feedback = feedback.trim();
    const newQuestions = [...questions];
    const questionIndex = questions.findIndex(o => o.id === questionId);
    const { type } = newQuestions[questionIndex];
    switch (type) {
      case 'radio':
      case 'boolean':
      case 'text':
        newQuestions[questionIndex] = { ...newQuestions[questionIndex], selected: feedback === '' ? [] : [feedback], error: false };
        break;
      case 'checkbox':
        const selected = newQuestions[questionIndex].selected ? [...newQuestions[questionIndex].selected] : [];
        selected.indexOf(feedback) === -1 ? selected.push(feedback) : !e.target.checked && selected.splice(selected.indexOf(feedback), 1);
        newQuestions[questionIndex]['error'] = newQuestions[questionIndex].isRequired && selected.length === 0;
        newQuestions[questionIndex] = { ...newQuestions[questionIndex], selected: [...selected] };
      default:
        break;
    }
    setQuestions(newQuestions);
  };

  const onSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    let error = false;

    let data = {
      reference: id,
      state: 'started',
    };
    let payload = {
      activityId: activityData.id,
      surveyId: activityData.data,
      poitsHistoryId: surveyStatus.activityEventId,
      userFeedback: [],
    };
    
    const newQuestions = [...questions];
    for (var i in newQuestions) {
      if (newQuestions[i].isRequired && newQuestions[i].selected?.length === 0) {
        newQuestions[i].error = true;
        error = true;
      }
      payload.userFeedback.push({
        id: newQuestions[i].id,
        answer: newQuestions[i].selected,
      });
    }
    if (!error) {
      try {
        const activityStatusRes = await getCompletedActivity([activityData.id]);
        if (activityStatusRes?.data) {
          const count = (activityStatusRes.data.length > 0) ? activityStatusRes.data[0]?.count:0;
          if (count >= activityData.times) {
            toast.error(`Unfortunately you have reached maximum number of times you can earn points for this action. No points for this action. No points will be added to your account.`);
            history.push('/earn-points');
          }else {
            if(payload.poitsHistoryId === 'none'){
              let pointResData = await props.pointsUpdate(data, 'post');
              payload['poitsHistoryId'] = pointResData?.data?.id
            }
            userSurveyFeedback(payload)
              .then(e => {
                if (e.httpCode >= 200 && e.httpCode < 300) {
                  toast.success('Successfully submitted.');
                  dispatch(balanceGetAction());
                  history.push('/earn-points');
                }
                setLoading(false);
              })
              .catch(error => {
                console.log(error);
                setLoading(false);
                toast.error(`Internal technical error. Please try after some time.`);
              });
          }
        } else{
          setLoading(false)
          toast.error(`Something went wrong.`); 
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        toast.error(`Internal technical error. Please try after some time.`);
      }
    } else {
      setQuestions(newQuestions);
      setLoading(false);
    }
  };
  return (
    <>
      <div className="page-heading smoke-bg">
        <div className="overlay-mask">
          <h1 className="bold">Survey</h1>
        </div>
      </div>
      <div className="portal-point-list-container">
        <Container fluid>
          <Row className="justify-content-center my-5">
            <div className="col-sm-9">
              <img src="/public/assets/images/activities/detailspages/lets_talk_grub.jpeg" alt="Avatar" style={{ width: '100%' }} />
            </div>
          </Row>
          <Row>
            <div className="col mt-3">
              <h2>{activityData['title'] ? activityData.title : ''}</h2>
              <p className="description">{activityData['description'] ? activityData.description : ''}</p>
            </div>
          </Row>

          <Row>
            <form className="col">
              <div className="questions-card">
                {questions.map(question => {
                  return (
                    <div className="card">
                      <FormControl>
                        <FormLabel className="mb-2" id="q1-radio-buttons-group-label">
                          {question.title}
                          {question.isRequired ? <Required /> : ''}
                        </FormLabel>
                        {(question.type === 'radio' || question.type === 'boolean') && (
                          <RadioGroup aria-labelledby="q1-radio-buttons-group-label" defaultValue="" name="radio-buttons-group" value={`${question.id}-${question.selected}`} onChange={handleAnswers}>
                            {question.options.map(option => {
                              return <FormControlLabel value={`${question.id}-${option.id}`} control={<Radio />} label={option.option} />;
                            })}
                          </RadioGroup>
                        )}

                        {question.type === 'checkbox' && (
                          <FormGroup row>
                            {question.options.map(option => {
                              return <FormControlLabel value={`${question.id}-${option.id}`} control={<Checkbox onChange={handleAnswers} name={option.id} color="primary" aria-label={option.option} />} label={option.option} />;
                            })}
                          </FormGroup>
                        )}

                        {question.type === 'text' && <TextField id={question.id} label="Your answer" placeholder="" minRows="4" multiline onChange={e => handleAnswers(e, 'text')} />}
                        {question.error && (
                          <div className="input-error" role="alert">
                            {`${question.type === 'text' ? 'Answer is required.' : 'Please select the option.'}`}
                          </div>
                        )}
                      </FormControl>{' '}
                    </div>
                  );
                })}
                <div className="mt-2 text-center">
                  <button type="submit" role="submit" className="btn btn-primary btn-submit" id="survey-submit-btn" onClick={onSubmit}>
                    <Trans>Submit</Trans>
                  </button>
                </div>
              </div>
            </form>
          </Row>

          <EarnPointList id={activityData['id']} type="activity" title={activityData['title']} />
        </Container>
      </div>
    </>
  );
};
export default React.memo(connect(null, { pointsUpdate, surveyDetail })(IsLoadingHOC(QuizQuestions, 'Loading....')), (prevProps, nextProps) => true);
