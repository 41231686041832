import React, { useState } from 'react';
import { Row, Col, Card, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import IsLoadingHOC from '../../components/IsLoadingHOC';
import { connect, useSelector } from 'react-redux';
import errorHtml from '../../components/Error';
import { login } from '../../redux/actions';
import { useHistory } from 'react-router-dom';
import SocialLogin from '../../components/SocialLogin';
import { Trans, useTranslation } from 'react-i18next';
import SecondFactorAuth from '../../components/SecondFactorAuth';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import Captcha from '../../components/CaptchaClick';
import Required from '../../components/Required';
import { TextField, createMuiTheme, FormControl, Select, MenuItem, InputLabel, InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
const formLabelsTheme = createMuiTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: '#db3131',
        '&$error': {
          color: '#db3131',
        },
      },
    },
  },
});
const Login = ({ setLoading, login }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const confige = useSelector(state => state.app.config);
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [showSecondFactorPopup, setShowSecondFactorPopup] = useState(false);
  const [secondFactorAuthData, setSecondFactorAuthData] = useState({});
  const [errors, setErrors] = useState({ username: '', password: '', captchaVal: '' });
  const [fpData, setFpData] = useState({ visitorId: '', requestId: '' });
  const [showPassword, setShowPassword] = useState(false);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowPassword = () => setShowPassword(show => !show);
  let [captchaValue, setCaptchaValue] = useState(false);
  let [captchaEnable, setCaptchaEnable] = useState(false);
  const onChangeHandle = e => {
    const { name, value } = e.target;
    if (name === 'username') {
      setUserName(e.target.value);
      value.length < 1 ? setErrors({ ...errors, username: `${t('Email Address should not be empty')}.` }) : setErrors({ ...errors, username: '' });
    }
    if (name === 'password') {
      value.length < 1 ? setErrors({ ...errors, password: `${t('Password Should not be empty')}.` }) : setErrors({ ...errors, password: '' });
      setPassword(e.target.value);
    }
  };
  const [redirectUrl, setRedirectUrl] = useState('/');
  React.useEffect(() => {
    if (confige.fingerprinting) {
      if (confige.fingerprinting.enable) {
        const fpPromise = FingerprintJS.load({ apiKey: confige.fingerprinting.clientKey });
        // Get the visitor identifier when you need it.
        fpPromise
          .then(fp => fp.get())
          .then(result => {
            setFpData({ visitorId: result.visitorId, requestId: result.requestId });
          });
      }
    }
    if (confige.captchaConfig) {
      if (confige.captchaConfig.loginCaptcha) {
        setCaptchaEnable(true);
      }
    }
  }, [confige]);
  React.useEffect(() => {
    let errorArray = JSON.parse(JSON.stringify(errors));
    if (captchaValue) {
      errorArray['captchaVal'] = '';
    }
    setErrors(errorArray);
    // eslint-disable-next-line
  }, [captchaValue]);
  React.useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const redtUrl = query.get('route') ? query.get('route') : '/';
    if (redirectUrl !== redtUrl) {
      setRedirectUrl(redtUrl);
    }
    // eslint-disable-next-line
  }, [window.location]);
  const handleError = () => {
    let value = false;
    const error = {};
    if (username.length < 1) {
      error.username = `${t('Email Address should not be empty')}.`;
      value = true;
    }
    if (password.length < 1) {
      error.password = `${t('Password Should not be empty')}.`;
      value = true;
    }
    if (password.length < 1) {
      error.password = `${t('Password Should not be empty')}.`;
      value = true;
    }
    if (!captchaValue && captchaEnable) {
      error.captchaVal = `${t('Please select captcha')}.`;
      value = true;
    }
    return { value, error };
  };

  const onSubmit = e => {
    if (!e) {
      return false;
    }
    e.preventDefault();
    const { value, error } = handleError();
    if (value) {
      setErrors({ ...errors, ...error });
      return false;
    }
    setLoading(true);
    let requestBody = { username, password, visitorId: fpData.visitorId, requestId: fpData.requestId };
    if (captchaEnable) {
      requestBody['captchaVal'] = captchaValue;
    }
    login(requestBody)
      .then(response => {
        setLoading(false);
        if (response.httpCode === 204) {
          setSecondFactorAuthData({ id: response.data });
          setShowSecondFactorPopup(true);
        } else {
          toast.success(`${t('Welcome back')}!`);
          history.push(redirectUrl === '/' ? redirectUrl : `/?route=${redirectUrl}`);
        }
      })
      .catch(error => {
        console.log('Error in login', error);
        if (error['httpCode'] === 403) {
          toast.error(`${t('Your account is suspended. Please contact helpdesk for more information')}.`);
        } else if (error['httpCode'] === 406) {
          toast.error(`${t('Error')}! ${t('User not verified')}.`);
        } else {
          toast.error(`${t('Invalid credentials')}.`);
        }
        setLoading(false);
      });
  };

  return (
    <div className="login-bg">
      <div className="register-page">
        <div className="container">
          <div className="row justify-content-center mt-5">
            <Card>
              <div className="">
                <h1>
                  LOG IN NOW TO ENTER LEAP® <br />
                  SMART REWARDS
                </h1>
                <p className="text-center mt-4 mb-2 px-3">
                  If you have previously registered before, please proceed by entering your email address and password; otherwise, please <a href="/signup">register here</a> to participate.{'  '}
                  <strong className="f-medium">Note: Registration requires an Age Verified LeapVapor.com account.</strong>
                </p>
              </div>
              <div className=" my-5">
                <form role="form" method="post">
                  <div className="form-group row px-40">
                    <TextField variant="filled" id="staticEmail" label={`${t('lang_key_email')}`} helperText={errors.username} error={errors.username} aria-required="true" aria-autocomplete="email" autoComplete="email" name="username" onChange={onChangeHandle} required />

                    {/* <div className="col-sm-12 labelfieldmove">
										<input type="text" 
											name="username" 
											onChange={onChangeHandle} 
											className="form-control" 
											id="staticEmail" 
											placeholder=" "
											aria-autocomplete="email" 
										/>
										<label htmlFor="staticEmail">
											<Trans>lang_key_email</Trans> <Required />
										</label>
										<div className="input-error" role="alert">{errors.username}</div>
									</div> */}
                  </div>
                  {/*[START:PASSWORD]*/}

                  <div className="form-group row px-40">
                    <TextField
                      variant="filled"
                      type={!showPassword ? 'password' : 'text'}
                      id="inputPassword"
                      label={`${t('password')}`}
                      helperText={errors.password}
                      error={errors.password}
                      aria-required="true"
                      aria-autocomplete="password"
                      autoComplete="password"
                      name="password"
                      onChange={onChangeHandle}
                      onKeyPress={e => e.key === 'Enter' && onSubmit()}
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  {/*[END:PASSWORD]*/}

                  {/*[START:GOOGLECAPTCHA]*/}
                  {captchaEnable ? (
                    <div className="form-group row px-40">
                      <label htmlFor="g-recaptcha-response" className="col-sm-12 invisible" aria-hidden="true">
                        Google captcha
                      </label>
                      <div className="col-sm-12">
                        <Captcha align="left" reset={0} parentCallback={setCaptchaValue} />
                        <div className="input-error" role="alert">
                          {errors.captchaVal}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {/*[END:GOOGLECAPTCHA]*/}
                  <div className="row px-40 flex-row-reverse align-items-center">
                    <div className="col-sm-6  text-center text-sm-right">
                      <button type="submit" role="submit" onClick={onSubmit} className="btn btn-primary btn-submit">
                        <Trans>login</Trans>
                      </button>
                    </div>
                    <div className="col-sm-6">
                      {/* FORGOT PASSWORD */}
                      <div className="forgot-pass-links text-center text-sm-left mt-4 mt-sm-0">
                        <a href="/forget-password">
                          <Trans>forgot password</Trans>
                        </a>
                      </div>
                      {/* END FORGOT PASSWORD */}
                    </div>
                  </div>
                  <div className="my-3">
                    <SocialLogin />
                  </div>
                  {/* <div className="register-links text-center mt-4">
									<div className="mb-3"><Trans>or</Trans></div>
									<a href="/register">
										<Trans>click here to register your account</Trans>.
									</a>
								</div> */}
                </form>
              </div>
            </Card>
          </div>
        </div>
      </div>
      <Modal show={showSecondFactorPopup}>
        <Modal.Body>{showSecondFactorPopup ? <SecondFactorAuth otpLen={6} waitingTime={120} data={secondFactorAuthData} /> : ''}</Modal.Body>
      </Modal>
    </div>
  );
};

export default connect(null, { login })(IsLoadingHOC(Login, 'Wait ....'));
