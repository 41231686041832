import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Container, Row, Col, Form, InputGroup, Modal, Button } from 'react-bootstrap';
import IsLoadingHOC from '../IsLoadingHOC';
import { Link } from 'react-router-dom';
import { validateCodeAction, balanceGetAction, getLockupAction } from '../../redux/actions';
import Captcha from '../CaptchaClick';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
// import Select from 'react-select';
import Required from '../Required';
import { TextField, createMuiTheme, FormControl, Select, MenuItem, InputLabel, FormHelperText } from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
const formLabelsTheme = createMuiTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: '#db3131',
        '&$error': {
          color: '#db3131',
        },
      },
    },
  },
});

const SubmitCode = props => {
  let [options, setOptions] = useState([]);
  const { t } = useTranslation();
  let dispatch = useDispatch();
  const config = useSelector(state => state.app.config);
  let [flaverList, setFlaverList] = useState([]);
  let [codelist, setCodeList] = useState([{ code: '', flavor: '' }]);
  let [error, setError] = useState([{ code: '', flavor: '' }]);
  let [showPopup, setShowPopup] = useState(false);
  let [captchaValue, setCaptchaValue] = useState(false);
  let [resetCaptcha, setResetCaptcha] = useState(0);
  let [captchaEnable, setCaptchaEnable] = useState(false);
  useEffect(() => {
    let len = codelist[0].code.length;
    let findInd = flaverList.findIndex(v => v.numberOfDigit === len);
    if (findInd >= 0) {
      let opt = [];
      for (let i in flaverList[findInd].flover) {
        opt.push({ value: flaverList[findInd].flover[i].name, label: flaverList[findInd].flover[i].id });
      }
      setOptions(opt);
    } else {
      setOptions([]);
    }
  }, [codelist[0].code]);
  useEffect(() => {
    loadFlavorList();
  }, []);
  const loadFlavorList = () => {
    let requestData = { lookups: ['PRODUCT_FLOVER'] };
    props.setLoading(true);
    getLockupAction(requestData)
      .then(response => {
        if (response.httpCode === 200 && response.data) {
          if (response.data.content) {
            let comListInd = response.data.content.findIndex(v => v.name === 'PRODUCT_FLOVER');
            let comList = [];
            if (comListInd >= 0) {
              comList = response.data.content[comListInd].data;
              setFlaverList(comList);
            }
          }
        }
        props.setLoading(false);
      })
      .catch(e => {
        props.setLoading(false);
      });
  };
  useEffect(() => {
    if (config.captchaConfig) {
      if (config.captchaConfig.packageCodeCaptcha) {
        setCaptchaEnable(true);
      }
    }
    //eslint-disable-next-line
  }, [config]);
  const updateCodeList = (e, ind) => {
    let codeTextFiled = [...codelist];
    let errorList = [...error];
    // if (/^[a-zA-Z0-9]*$/.test(e.target.value)) {
    codeTextFiled[ind][e.target.name] = e.target.value;
    if (e.target.name === 'code') {
      if (!e.target.value) {
        errorList[ind].code = 'Product code is required.';
      } else if (e.target.value && [8, 9, 10, 12, 14].indexOf(e.target.value.length) < 0) {
        errorList[ind].code = 'Product code must be 8,9,10,12 or 14 digits.';
      } else {
        errorList[ind].code = '';
      }
      codeTextFiled[ind]['flavor'] = '';
    } else if (e.target.name === 'flavor') {
      if (!e.target.value) {
        errorList[ind].flavor = 'Please select.';
      } else {
        errorList[ind].flavor = '';
      }
    }
    setError(errorList);
    setCodeList(codeTextFiled);
  };
  const resetComponent = () => {
    let tempresetCaptcha = resetCaptcha + 1;
    setResetCaptcha(tempresetCaptcha);
    setCodeList([{ code: '', flavor: '' }]);
    setCaptchaValue(false);
  };
  const formSubmit = () => {
    let codeTextFiled = [...codelist];
    let errorList = [...error];
    let isError = false;
    for (let ind in errorList) {
      if (!codeTextFiled[ind].code) {
        errorList[ind].code = 'Product code is required.';
        isError = true;
      } else if (codeTextFiled[ind].code && [8, 9, 10, 12, 14].indexOf(codeTextFiled[ind].code.length) < 0) {
        errorList[ind].code = 'Product code must be 8,9,10,12 or 14 digits.';
        isError = true;
      } else {
        errorList[ind].code = '';
      }
      if (!codeTextFiled[ind].flavor) {
        errorList[ind].flavor = 'Please select.';
        isError = true;
      } else {
        errorList[ind].flavor = '';
      }
    }
    setError(errorList);
    if (isError) {
      return false;
    }
    if (!captchaValue && captchaEnable) {
      toast.error(`${t('Error')}! ${t('Please select captcha')}.`);
      return false;
    }
    let submitCodes = codeTextFiled.filter(v => v !== '');
    props.setLoading(true);
    let formData = { packageCodes: submitCodes };
    if (captchaEnable) {
      formData['captchaVal'] = captchaValue;
    }
    validateCodeAction(formData)
      .then(responseData => {
        if (responseData.httpCode >= 200 && responseData.httpCode <= 299) {
          resetComponent();
          dispatch(balanceGetAction());
          toast.success(`The package code is validated successfully.`);
        } else {
          toast.error(`Oops! It looks like you entered the wrong product code. Please try again.`);
          console.log('responseData', responseData);
        }
        props.setLoading(false);
      })
      .catch(err => {
        toast.error(`Oops! It looks like you entered the wrong product code. Please try again.`);
        props.setLoading(false);
        console.log('Response Error:', JSON.stringify(err));
      });
  };
  return (
    <div>
      <div className="page-heading smoke-bg">
        <div className="overlay-mask">
          <h1 className="bold">Submit Code</h1>
        </div>
      </div>
      <div className=" submit-page">
        <Container>
          <Row className="justify-content-center">
            <Col sm="7" className="text-center">
              <div className="dark-bg submit-content-bg px-sm-5 px-3">
                <h2 className="pt-3">
                  Upload a Leap® Smart Rewards Code <br />
                  found in participating product packaging
                </h2>
                <div className="divider"></div>
                <p>
                  Click{' '}
                  <label onClick={e => setShowPopup(true)} className="lable-link">
                    here
                  </label>{' '}
                  to see how many points your upload is worth!
                </p>

                <div>
                  <div className="row justify-content-center">
                    {codelist.map((v, i) => {
                      return (
                        <div key={`row${i}`} className="addproductcode col-sm-10">
                          {/*[START:Product code]*/}
                          <TextField
                            label="Enter product code"
                            variant="filled"
                            className="mb-4"
                            id={`code${i}`}
                            name={`code`}
                            error={error[i].code}
                            helperText={error[i].code}
                            onChange={e => {
                              updateCodeList(e, i);
                            }}
                            value={v.code}
                            maxLength={20}
                            required
                          />
                          {options.length > 0 ? (
                            <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }} className="mb-4">
                              <InputLabel id="flavornew-label">Choose your flavor *</InputLabel>
                              <Select
                                labelId="flavornew-label"
                                id="flavornew"
                                required
                                name="flavor"
                                error={error[i].flavor}
                                helperText={error[i].flavor}
                                onChange={e => {
                                  updateCodeList(e, i);
                                }}
                                value={v.flavor}>
                                {options.map(o => (
                                  <MenuItem value={o.value}>{o.label}</MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>{error[i].flavor}</FormHelperText>
                            </FormControl>
                          ) : (
                            ''
                          )}
                        </div>
                      );
                    })}
                    <div className='col-sm-10'>
                      <p>
                        <i>If your code has a U or a V and it's not working, don't panic! They look very similar. Please try both ways if the first guess is wrong.</i>
                      </p>
                      <p>
                        Have a Yellow Sticker? Click{' '}
                        <a className="link" href="/earn-points/yellow-pincode">
                          here
                        </a>{' '}
                        instead
                      </p>
                    </div>
                  </div>
                  {captchaEnable ? (
                    <div className="justify-content-md-center mt-3">
                      <Captcha reset={resetCaptcha} parentCallback={setCaptchaValue} />
                    </div>
                  ) : (
                    ''
                  )}
                </div>

                <div className="btn-div pb-4 pt-3 px-40">
                  <button type="button" onClick={formSubmit} className="btn btn-primary col" id="submit-code-btn">
                    <Trans>Submit</Trans>
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Modal dialogClassName="modal-80w modal-green-bg " show={showPopup} onHide={e => setShowPopup(false)} size="sm=" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Body className="show-grid">
            <Container>
              <Row>
                <Col>
                  <div className="text-center border-1 pt-3">
                    <p>Leap Device Kits = 40 points</p>
                    <p>XL USB Charger = 12 points</p>
                    <p>Leap Pods = 16 points</p>
                    <p>Leap Go = 25 points </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default IsLoadingHOC(SubmitCode, 'Loading....');
