import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Alert, Card } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import IsLoadingHOC from './IsLoadingHOC';
import { helpQueryAction } from '../redux/actions';
import { toast } from 'react-toastify';
import Captcha from './CaptchaClick';
import { useSelector } from 'react-redux';
import { TextField, createMuiTheme, FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
const formLabelsTheme = createMuiTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: '#db3131',
        '&$error': {
          color: '#db3131',
        },
      },
    },
  },
});

const Support = props => {
  const { t } = useTranslation();
  const [data, setData] = useState({
    name: '',
    email: '',
    description: '',
    captchaVal: '',
  });
  const config = useSelector(state => state.app.config);
  let [captchaValue, setCaptchaValue] = useState(false);
  let [resetCaptchaValue, setResetCaptchaValue] = useState(0);
  const [error, setError] = useState({ name: '', email: '', description: '', captchaVal: '' });
  // eslint-disable-next-line
  const validationError = { description: '', name: /^[A-Za-z]+$/, email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, lastName: /^[A-Za-z]+$/, captchaVal: '' };
  let [captchaEnable, setCaptchaEnable] = useState(false);
  useEffect(() => {
    let errorArray = JSON.parse(JSON.stringify(error));
    if (captchaValue) {
      errorArray['captchaVal'] = '';
      let reqd = JSON.parse(JSON.stringify(data));
      reqd.captchaVal = captchaValue;
      setData(reqd);
    }
    setError(errorArray);
    // eslint-disable-next-line
  }, [captchaValue]);
  useEffect(() => {
    if (config.captchaConfig) {
      if (config.captchaConfig.supportCaptcha) {
        setCaptchaEnable(true);
      }
    }
  }, [config]);
  const handleChange = e => {
    let key = e.target.name;
    let val = e.target.value;
    let reqd = JSON.parse(JSON.stringify(data));
    let errd = JSON.parse(JSON.stringify(error));
    if (val.match(validationError[key]) || val === '' || key === 'email') {
      reqd[key] = val;
    }
    if (key === 'name') {
      if (!reqd[key] || reqd[key].trim().length < 1) {
        errd[key] = `Name is required.`;
      } else {
        errd[key] = ``;
      }
    } else if (key === 'email') {
      if (val === '') {
        errd[key] = `Email is required.`;
      } else if (!reqd[key].match(validationError[key])) {
        errd[key] = `Please enter a valid email address.`;
      } else {
        errd[key] = ``;
      }
    } else if (key === 'description') {
      if (!reqd[key] || val.trim().length < 1) {
        errd[key] = `Message is required.`;
      } else {
        errd[key] = ``;
      }
    }
    setError(errd);
    setData(reqd);
  };
  const onSubmit = e => {
    e.preventDefault();
    let reqd = JSON.parse(JSON.stringify(data));
    let errd = JSON.parse(JSON.stringify(error));
    let isError = false;
    for (let key in reqd) {
      let val = reqd[key].trim();
      if (key === 'name') {
        if (!val || val.length < 1) {
          isError = true;
          errd[key] = `Name is required.`;
        } else {
          errd[key] = ``;
        }
      } else if (key === 'email') {
        if (val === '') {
          errd[key] = `Email is required.`;
          isError = true;
        } else if (!val.match(validationError[key])) {
          errd[key] = `Please enter a valid email address.`;
          isError = true;
        } else {
          errd[key] = ``;
        }
      } else if (key === 'description') {
        if (!val || val.length < 1) {
          errd[key] = `Message is required.`;
          isError = true;
        } else {
          errd[key] = ``;
        }
      } else if (key === 'captchaVal' && captchaEnable) {
        if (!val || val.length < 2) {
          errd[key] = `Please check Captcha`;
          isError = true;
        } else {
          errd[key] = ``;
        }
      }
    }
    setError(errd);
    if (!isError) {
      props.setLoading(true);
      let requestData = { email: reqd.email, name: reqd.name, message: reqd.description };
      if (captchaEnable) {
        requestData['captchaVal'] = reqd.captchaVal;
      }
      helpQueryAction(requestData)
        .then(response => {
          for (let key in reqd) {
            reqd[key] = '';
          }
          setResetCaptchaValue(resetCaptchaValue + 1);
          setData(reqd);
          toast.success(`Thank you for contacting us. Your message has been successfully sent. We will contact you very soon!`);
          props.setLoading(false);
        })
        .catch(error => {
          toast.error(t('Something went wrong'));
          props.setLoading(false);
          setResetCaptchaValue(resetCaptchaValue + 1);
        });
    }
  };
  return (
    <div>
      <div className="support-page">
        <div className="page-heading profile-img">
          <img src="/public/assets/images/profile-manage.jpg" alt="Redeem Your Leap® Smart Rewards" />
          <div className="overlay-mask">
            <h1>Get in touch with us today</h1>
            <p>Get answers to the most asked questions!</p>
          </div>
        </div>
      </div>
      <div className="my-5">
        <Container fluid id="support-content">
          <div className="">
            <div>
              <Row>
                <Col className="col-12 col-sm-12 col-md-4 mb-5">
                  <Form role="form" method="post">
                    <Col>
                      <h1 className="text-left theme-color bold">SUPPORT</h1>
                    </Col>
                    {/*[START:FIRSTNAME]*/}
                    <Col className="mb-3">
                      <TextField variant="filled" id="name" label={`${t('Name')}`} helperText={error.name} error={error.name} aria-required="true" aria-autocomplete="name" value={data.name} name="name" onChange={handleChange} required />
                    </Col>
                    {/*[END:FIRSTNAME]*/}

                    {/*[START:EMAIL]*/}
                    <Col className="mb-3">
                      <TextField variant="filled" id="email" name="email" label={`${t('lang_key_email')}`} helperText={error.email} error={error.email} aria-required="true" aria-autocomplete="email" value={data.email} onChange={handleChange} required />
                    </Col>
                    {/* <Col className="form-group labelfieldmove mb-4">
											<Form.Control size="md" 
											type="email" 
											value={data.email} 
											name="email" 
											onChange={handleChange} 
											onFocus={handleChange} 
											id="email" 
											className={error.email? "input-error" : ""}
											placeholder=" "
											aria-required="true"
											aria-autocomplete="email"
											/>
											<label htmlFor="email">Email Address</label>
											<div className="input-error" role="alert">{error.email}</div>
										</Col> */}
                    {/*[END:EMAIL]*/}
                    <Col className="mb-3">
                      <TextField variant="filled" multiline={true} minRows={8} id="description" name="description" label="Message" helperText={error.description} error={error.description} value={data.description} onChange={handleChange} aria-required="true" required />
                    </Col>
                    {/* <Col className="form-group labelfieldmove mb-4">
											<Form.Control size="md" 
											as="textarea" 
											value={data.description} 
											placeholder=" "
											onChange={handleChange} 
											name="description" 
											id="description"
											style={{ height: "100px" }} 
											onFocus={handleChange} 
											className={error.description? "input-error" : ""}
											aria-required="true"
											aria-autocomplete="description"
											/>
											<label htmlFor="description">Message</label>
											<div className="input-error" role="alert">{error.description}</div>
										</Col> */}
                    {captchaEnable ? (
                      <Col className="mb-4">
                        <Captcha reset={resetCaptchaValue} parentCallback={setCaptchaValue} />
                        <div className="input-error" role="alert">
                          {error.captchaVal}
                        </div>
                      </Col>
                    ) : (
                      ''
                    )}
                    <Col className="text-left">
                      <Button variant="primary" type="submit" role="submit" className="themeBackground" size="lg" id="register-submit-btn" onClick={onSubmit}>
                        <Trans>Submit</Trans>
                      </Button>
                    </Col>
                  </Form>
                </Col>
                <Col className="col-12 col-sm-12 col-md-8">
                  <h1 className="text-left theme-color bold">FREQUENTLY ASKED QUESTIONS</h1>
                  <div className="mb-4  home-faq">
                    <div id="accordion">
                      <div className="card">
                        <div className="card-header" id="headingOne">
                          <button type="button" className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            <div className="row">
                              <div className="col-11">
                                <h5 className="mb-0">WHAT IS LEAP® SMART REWARDS?</h5>
                              </div>
                              <div className="col-1">
                                <span className="material-icons float-right show-more">expand_more</span>
                                <span className="material-icons float-right show-less">expand_less</span>
                              </div>
                            </div>
                          </button>
                        </div>
                        <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                          <div className="card-body">Leap Smart Rewards is a loyalty program that allows Leap customers to earn points towards exciting prizes and entries into giveaway and sweepstakes.</div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingTwo">
                          <button type="button" className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <div className="row">
                              <div className="col-11">
                                <h5 className="mb-0">HOW DO I EARN LEAP® SMART REWARDS POINTS?</h5>
                              </div>
                              <div className="col-1">
                                <span className="material-icons float-right show-more">expand_more</span>
                                <span className="material-icons float-right show-less">expand_less</span>
                              </div>
                            </div>
                          </button>
                        </div>
                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                          <div className="card-body">You can earn points for all sorts of activities, including uploading codes found in Leap products, taking surveys, referring friends, and more.</div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingThree">
                          <button type="button" className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            <div className="row">
                              <div className="col-11">
                                <h5 className="mb-0">WHAT REWARDS CAN I EARN WITH LEAP® SMART REWARDS POINTS?</h5>
                              </div>
                              <div className="col-1">
                                <span className="material-icons float-right show-more">expand_more</span>
                                <span className="material-icons float-right show-less">expand_less</span>
                              </div>
                            </div>
                          </button>
                        </div>
                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                          <div className="card-body">Leap Smart Rewards points can be redeemed for digital gift cards, with new rewards coming soon.</div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingFour">
                          <button type="button" className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            <div className="row">
                              <div className="col-11">
                                <h5 className="mb-0">HOW DO I VIEW MY LEAP® SMART REWARDS POINTS BALANCE?</h5>
                              </div>
                              <div className="col-1">
                                <span className="material-icons float-right show-more">expand_more</span>
                                <span className="material-icons float-right show-less">expand_less</span>
                              </div>
                            </div>
                          </button>
                        </div>
                        <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                          <div className="card-body">Your points balance is on every page in the top-right corner.</div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingFive">
                          <button type="button" className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            <div className="row">
                              <div className="col-11">
                                <h5 className="mb-0">HOW DO I REDEEM MY LEAP® SMART REWARDS POINTS?</h5>
                              </div>
                              <div className="col-1">
                                <span className="material-icons float-right show-more">expand_more</span>
                                <span className="material-icons float-right show-less">expand_less</span>
                              </div>
                            </div>
                          </button>
                        </div>
                        <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                          <div className="card-body">
                            To see which rewards you can redeem with your Leap® Rewards points, visit{' '}
                            <a href="https://rewards.leapvapor.com/rewards" target="_blank">
                              Rewards.LeapVapor.com/rewards
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingSix">
                          <button type="button" className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                            <div className="row">
                              <div className="col-11">
                                <h5 className="mb-0">IS THERE A LIMIT TO THE NUMBER OF POINTS I CAN EARN IN A DAY?</h5>
                              </div>
                              <div className="col-1">
                                <span className="material-icons float-right show-more">expand_more</span>
                                <span className="material-icons float-right show-less">expand_less</span>
                              </div>
                            </div>
                          </button>
                        </div>
                        <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                          <div className="card-body">Leap® Smart Rewards members may upload up to 15 Leap product codes per day. If you have already submitted 15 codes in one 24-hour period and you attempt to submit another, a message will pop up on the upload page stating, “Sorry, you have reached the limit for today, please try again tomorrow.” There is no limit to the number of points you can earn per day with other Leap Smart Rewards activities, so earn as many as you like!</div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingSeven">
                          <button type="button" className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                            <div className="row">
                              <div className="col-11">
                                <h5 className="mb-0">CAN I COMPLETE THE SAME LEAP® SMART REWARDS ACTIVITY MORE THAN ONCE?</h5>
                              </div>
                              <div className="col-1">
                                <span className="material-icons float-right show-more">expand_more</span>
                                <span className="material-icons float-right show-less">expand_less</span>
                              </div>
                            </div>
                          </button>
                        </div>
                        <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
                          <div className="card-body">Only Pin Code uploads and friend referral activities can be repeated more than once. If you attempt to repeat a specific Leap® Rewards activity that you have already completed, a message will up on the activity page stating, “Unfortunately, you have reached the maximum number of times you can earn points for this action. No points will be added to your account.</div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingEight">
                          <button type="button" className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                            <div className="row">
                              <div className="col-11">
                                <h5 className="mb-0">WHAT DO I DO IF I AM HAVING TROUBLE PARTICIPATING IN LEAP® SMART REWARDS?</h5>
                              </div>
                              <div className="col-1">
                                <span className="material-icons float-right show-more">expand_more</span>
                                <span className="material-icons float-right show-less">expand_less</span>
                              </div>
                            </div>
                          </button>
                        </div>
                        <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-parent="#accordion">
                          <div className="card-body">
                            If you have any questions or concerns about Leap Rewards, please contact our Customer Service at <a href="mailto:support@receiptprocessor.com">support@receiptprocessor.com</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default IsLoadingHOC(Support, 'Wait .....');
