import React from "react";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { connect } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { forgetPassword } from "../../redux/actions";
import { Trans, useTranslation} from "react-i18next";
import {  TextField, createMuiTheme, FormControl, Select, MenuItem, InputLabel, } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
const formLabelsTheme = createMuiTheme({
	overrides: {
		MuiFormLabel: {
			asterisk: {
				color: "#db3131",
				"&$error": {
					color: "#db3131",
				},
			},
		},
	},
});
const ForgetPassword = ({ setLoading, login, forgetPassword }) => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <div className="">
      <div className="register-page">
        <div className="container">
          <div className="row justify-content-center page-form">
            <div className="col-sm-9">
              <h1 className="h1 mb-5">FORGOT PASSWORD</h1>
              <p className="mb-5 text-center">Enter the email address associated with your Leap Rewards account.</p>
              <div className="row justify-content-center">
                <div className="col">
                  <Formik
                    initialValues={{ email: '' }}
                    validationSchema={Yup.object({
                      email: Yup.string().required(`${t('lang_key_email')} ${t('Should not be empty')}.`),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                      //console.log(values);
                      setLoading(true);
                      forgetPassword(values.email)
                        .then(response => {
                          console.log(response);
                          setSubmitting(false);
                          setLoading(false);
                          toast.success(response.message);
                          history.push('/login');
                        })
                        .catch(error => {
                          console.log(error);
                          setSubmitting(false);
                          setLoading(false);
                          if (Array.isArray(error)) {
                            // error.forEach(item => {
                            //   toast.error(item.message);
                            // });
                            toast.error(`${t(`Please provide your registered email Id`)}.`);
                          } else {
                            toast.error(`${t(`Something went wrong`)}.`);
                          }
                        });

                      // setTimeout(() => {
                      // 	alert(JSON.stringify(values, null, 2));
                      // 	setSubmitting(false);
                      // }, 400);
                    }}>
                    <Form role="form">
                      <div className="form-group row">
						{/* <TextField 
							variant="filled" 
							name="email" 
							type="text" 
							id="staticEmail" 
							label={`${t('lang_key_email')}`} 
							helperText={ErrorMessage.email} 
							error={ErrorMessage.email} 
							aria-required="true" 
							autoComplete="email"
							required 
						/> */}
                        <div className="col-sm-12 labelfieldmove">
                          <Field
                            name="email"
                            type="text"
                            id="staticEmail"
                            className="form-control"
                            placeholder=" "
                            // placeholder="Enter registered email"
                            aria-required="true"
                          />
                          <label htmlFor="staticEmail">
                            <Trans>lang_key_email</Trans>
                          </label>
                          <span className="input-error" role="alert">
                            <ErrorMessage name="email" />
                          </span>
                          {/* <input data-test="reset-password" type="text" name="email" value={email} onChange={(e) => setEmail(e.target.value)}  className="form-control" id="staticEmail" placeholder="Email" /> */}
                        </div>
                      </div>

                      <div className="row flex-row-reverse align-items-center">
                        <div className="col-sm-6 text-center text-sm-right">
                          <button type="submit" className="btn btn-primary btn-submit">
                            SUBMIT
                          </button>
                        </div>
                        <div className="col-sm-6">
                          <div className="register-links text-center text-sm-left mt-4 mt-sm-0">
                            <a href="/login">Login Instead?</a>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { forgetPassword })(IsLoadingHOC(ForgetPassword, 'Wait ....'));
