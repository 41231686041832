import React, { useEffect, useState } from 'react';
import Card from './Card';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { portalPointAction, activityStatusAction, whatsNewActivitiesAction, getStreakCountAction } from '../redux/actions';
import { Button, Container, Col, Row, Modal } from 'react-bootstrap';
import IsLoadingHOC from '../components/IsLoadingHOC';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
const PortalPointList = props => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [popupShow, setPopupShow] = useState(false);
  const [viewMore, setViewMore] = useState(true);
  const [page, setPage] = useState(0);
  const [actionType, setActionType] = useState('');
  const [pageRedirect, setPageRedirect] = useState(false);
  const [activityStatus, setActivityStatus] = useState({ status: false, activityEventId: '', activityData: {} });
  const activityTypeList = [
    { id: 'view', name: 'Video' },
    { id: 'survey', name: 'Survey' },
    { id: 'upload', name: 'Upload' },
  ];
  const pagesize = props.pagesize ? props.pagesize : 4;
  useEffect(() => {
    getNextData(0);
    getStreakCountAction('')
      .then(responseData => {
        if (responseData?.httpCode === '201') {
          toast.success(`${t('Congratulations')}! ${t('You have earned')} ${responseData?.data?.points} ${t('Points')}`);
        }
      })
      .catch(err => {
        console.log('Response Error:', JSON.stringify(err));
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (activityStatus.status) {
      clickEarnCompleted();
      setActivityStatus({ status: false, activityEventId: '', activityData: {} });
    }
  }, [activityStatus]);
  const getNextData = page => {
    const actionType = ['view', 'survey', 'refer', 'read', 'share', 'games', 'click', 'login-n-earn'];
    props.setLoading(true);
    if (props.isHome) {
      whatsNewActivitiesAction()
        .then(response => {
          let respData = response.data;
          if (respData.activityResult) {
            respData.activityResult = respData.activityResult.map(v => {
              let o = Object.assign({}, v);
              if (o.isTierEnabled && o.tierPoints) {
                o.points = o.tierPoints;
              }
              return o;
            });
          }
          setData([...(respData?.activityResult || []), ...(respData?.contestResult || []), ...(respData?.rewardResult || [])]);
          setFilteredData([...(respData?.activityResult || []), ...(respData?.contestResult || []), ...(respData?.rewardResult || [])]);
          // setPage(page + 1);
          // setViewMore(response.end);
          props.setLoading(false);
        })
        .catch(ex => {
          console.log('Error In API Call', ex);
          props.setLoading(false);
        });
    } else {
      props
        .portalPointAction(page + 1, actionType, page === 0 ? pagesize - 1 : pagesize)
        .then(response => {
          let activityList = response.data;
          if (activityList) {
            activityList = activityList.map(v => {
              let o = Object.assign({}, v);
              if (o.isTierEnabled && o.tierPoints) {
                o.points = o.tierPoints;
              }
              return o;
            });
          }
          setData([...data, ...activityList]);
          setFilteredData([...filteredData, ...activityList]);
          setPage(page + 1);
          setViewMore(response.end);
          props.setLoading(false);
        })
        .catch(ex => {
          console.log('Error In API Call', ex);
          props.setLoading(false);
        });
    }
  };

  const clickEarnInit = async objParam => {
    let { id, points, data: socialURL, maxAttempt, times } = objParam;
    if (socialURL) {
      window.open(socialURL, '_blank');
    }
    let data = {
      reference: id,
      state: 'started',
    };
    props.setLoading(true);
    props
      .pointsUpdate(data, 'post')
      .then(responseData => {
        props.setLoading(false);
        if (responseData['data']) {
          setActivityStatus(Object.assign({}, activityStatus, { status: true, activityEventId: responseData.data.id, activityData: objParam }));
        } else {
          setActivityStatus(Object.assign({}, activityStatus, { status: false, activityEventId: 'none', activityData: objParam }));
        }
      })
      .catch(err => {
        setActivityStatus(Object.assign({}, activityStatus, { status: false, activityEventId: 'none', activityData: objParam }));
        props.setLoading(false);
        let msg = '';
        if (err['errors'] && err['errors'][0]['message']) {
          msg = `${t('Sorry')}! ${t(err['errors'][0]['message'])}.`;
        } else {
          msg = `${t('Sorry')}! ${t("You've earned the maximum points possible for this action")}.`;
        }
        toast.error(msg);
      });
  };
  const clickEarnCompleted = () => {
    let data = {
      id: activityStatus.activityEventId,
      state: 'completed',
    };
    if (activityStatus.activityEventId !== '') {
      props
        .pointsUpdate(data, 'put', 'read')
        .then(responseData => {
          if (responseData['data']) {
            toast.success(`${t('Congratulations')}! ${t('You have earned')} ${activityStatus.activityData.points} ${t('Points')}.`);
          } else {
            toast.error(`${t('Sorry')}! ${t("You've earned the maximum points possible for this action")}.`);
          }
        })
        .catch(err => {
          if (activityStatus.activityEventId === 'none') {
            toast.error(`${t('Sorry')}! ${t("You've earned the maximum points possible for this action")}.`);
          } else {
            let msg = '';
            if (err['errors'] && err['errors'][0]['message']) {
              msg = `${t('Sorry')}! ${t(err['errors'][0]['message'])}.`;
            } else {
              msg = `${t('Sorry')}! ${t("You've earned the maximum points possible for this action")}.`;
            }
            toast.error(t(msg || 'Something went wrong'));
          }
          //console.log("Response Error:", JSON.stringify(err));
        });
    } else {
      toast.error(`${t('Sorry')}! ${t("You've earned the maximum points possible for this action")}.`);
    }
  };

  const activityCLick = async (activityData, redirectUrl) => {
    try {
      if (activityData['actionType'] === 'upload') {
        setPageRedirect(activityData.id);
      }
      if (activityData['actionType'] === 'click') {
        //clickEarnInit(activityData);
        setPageRedirect(activityData.data);
      } else {
        setPageRedirect(redirectUrl);
      }
    } catch (err) {
      console.log('Error in Activity status check', err);
      props.setLoading(false);
    }
  };
  const handlePopupClose = () => {
    setPopupShow(false);
  };
  useEffect(() => {
    if (!actionType) {
      setFilteredData(data);
    } else {
      let d = data.filter(v => v.actionType === actionType);
      setFilteredData(d);
    }
  }, [actionType]);
  if (pageRedirect) {
    return <Redirect push to={pageRedirect} />;
  } else if (props.isHome) {
    return (
      <>
        {filteredData.map((item, index) => {
          return (
            <Col key={index}>
              <Card>
                <div onClick={e => activityCLick(item, `${item.actionType === 'click' ? item.data : '/earn-points-action-preview/'}${item.id}`)} className="activity-block">
                  <div className="activity-img-holder d-flex align-items-center">
                    <img src={item.cardHeaderImage} alt="Avatar" style={{ width: '100%' }} />
                  </div>
                  <div className="card-body">
                    <div className="activity-info">
                      {/* <div className="points">
                        <Trans>Points</Trans>: {item.points}
                      </div> */}
                      <h5 dangerouslySetInnerHTML={{ __html: item.title }} />
                      {props.isHome ? <p>{item.description}</p> : ''}
                    </div>

                    <Button activity-type={item.actionType} id={`earn-btn-${item.id}`} className="cart-button">
                      {props.isHome && item.actionType === 'click' ? (item.data === '/earn-points/pincode' ? 'Enter Code' : 'Earn Now') : `Earn ${item.actionType === 'login-n-earn' ? 'UP TO' : ''} ${item.points} PTS Now`}
                    </Button>
                  </div>
                </div>
              </Card>
            </Col>
          );
        })}
      </>
    );
  } else {
    return (
      <Container fluid>
        <div className="post-login">
          <Container fluid>
            {props.viewPageBtn ? (
              <Row md={1} className="mb-3">
                <Col>
                  <h3 className="d-inline-block">What are you looking for?</h3>
                  <div className="form-group slect-labelfieldmove d-inline-block">
                    <select name="activityType" value={actionType} onChange={e => setActionType(e.target.value)} className="form-select" id="activityType">
                      <option value="">Show All</option>
                      {activityTypeList.map((v, i) => {
                        return <option value={v.id}>{v.name}</option>;
                      })}
                    </select>
                    <label htmlFor="activityType">Select an option</label>
                  </div>
                </Col>
              </Row>
            ) : (
              ''
            )}
            <Row xs={1} md={2} lg={4} className="card_holder activity-box">
              {filteredData.map((item, index) => {
                return (
                  <Col key={index}>
                    <Card>
                      <div onClick={e => activityCLick(item, `${item.actionType === 'upload' ? '' : '/earn-points-action-preview/'}${item.id}`)} className="activity-block">
                        <div className="activity-img-holder d-flex align-items-center">
                          <img src={item.cardHeaderImage} alt="Avatar" style={{ width: '100%' }} />
                        </div>
                        <div className="card-body">
                          <div className="activity-info">
                            {/* <div className="points">
                              <Trans>Points</Trans>: {item.points}
                            </div> */}
                            <h5 dangerouslySetInnerHTML={{ __html: item.title }} />
                            {props.isHome ? <p>{item.description}</p> : ''}
                          </div>

                          <Button activity-type={item.actionType} id={`earn-btn-${item.id}`} className="cart-button btn btn-primary">
                            {props.isHome && item.actionType === 'upload' ? 'Enter Code' : `Earn ${item.actionType === 'upload' ? 'UP TO' : ''} ${item.points} PTS Now`}
                          </Button>
                        </div>
                      </div>
                    </Card>
                  </Col>
                );
              })}
            </Row>
            {viewMore && props.viewPageBtn ? (
              <div className="text-center col my-4">
                {' '}
                <Button variant="secondary" id="view-more-earn-pointlist-btn" className="viewMore cart-button btn btn-primary" onClick={() => getNextData(page)}>
                  <Trans>View More</Trans>
                </Button>{' '}
              </div>
            ) : (
              ''
            )}
          </Container>
        </div>
        <Modal show={popupShow} onHide={handlePopupClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Trans>Sorry</Trans>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="survey-message-div">
              <p className="alignCenter">
                <Trans>You have already earned the maximum number of points possible for this action</Trans>.
              </p>
            </div>
          </Modal.Body>
          <p align="center">
            <Link to={'#'} className="btn btn-link" onClick={handlePopupClose}>
              <Trans>Close this window</Trans>
            </Link>
          </p>
          <p align="center">
            <Link className="btn btn-link" onClick={handlePopupClose} to="/">
              <Trans>Go back to home</Trans>
            </Link>
          </p>
        </Modal>
      </Container>
    );
  }
};

export default connect(null, { portalPointAction })(IsLoadingHOC(PortalPointList, 'Loading....'));
