import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Logout from './Logout';
import { notificationGetAction } from '../redux/actions';
import { defaultOptions } from '../config';
import NotificationCenter from './NotificationCenter';
import { useTranslation, Trans } from 'react-i18next';
import { store } from '../redux/store';

const TopRight = props => {
  const [showNotification, setShowNotification] = useState(false);
  const { t } = useTranslation();
  const user = useSelector(({ user }) => user);
  const dispatch = useDispatch();
  let notificationList = useSelector(({ app }) => (app.notificationCenter ? app.notificationCenter : []));
  useEffect(() => {
    const interval = setInterval(() => {
      loadNotification();
    }, defaultOptions.loadNotificationInterval);
    return () => clearInterval(interval);
    //eslint-disable-next-line
  }, []);
  const loadNotification = () => {
    const accessToken = store.getState('user').user.accessToken;
    if (accessToken) {
      const notifications = store.getState('app').app.notificationCenter;
      if (notifications.length > 0) {
        dispatch(notificationGetAction(notifications[0]['time']));
      } else {
        dispatch(notificationGetAction(''));
      }
    }
  };
  const pushDataLayer = (menu, url) => {
    window.dataLayer.push({
      event: 'element_clicked',
      elementType: 'MLink',
      path: url,
      userId: user?.id || '',
      email: user?.email || '',
      username: user?.username || '',
      name: user?.name || '',
      refId: '',
      refType: '',
      elementTag: menu,
      createdDate: new Date(),
    });
  };
  return (
    <Fragment>
      {user.accessToken ? (
        <li className="user-pos ml-2">
          <div className="dropdown user-menu" id="useruldropdownmenu">
            {/* {t("Welcome")} {user.name ? user.name : user.username}!{" "} */}
            <button id="href-profile" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span className="user-menu-icon-mobile d-sm-none">
                <i className="material-icons"> supervised_user_circle</i>
              </span>
              <div className="user-menu-icon d-none d-sm-flex">
                <img className="menu-reward-icon" src="/public/assets/images/Leap-reward-icon.png" />
                <div className="user-name">
                  <strong>
                    {user.pointBalance} <Trans>pts</Trans>
                  </strong>
                  <p>{user.name ? user.name : user.username}</p>
                </div>
              </div>
            </button>
            <div className="user-menu-wrap">
              <div className="user-menu-panel">
                <div className="d-block d-sm-none">
                  <p className="user-menu-heading">Account Menu</p>
                  <div className="user-account">
                    <img className="menu-reward-level" src="/public/assets/images/Leap-reward-icon.png" />
                    <div className="user-pts">
                      <strong>
                        {user.pointBalance} <Trans>pts</Trans>
                      </strong>
                      <p>{user.name ? user.name : user.username}</p>
                    </div>
                  </div>
                </div>
                <ul className="dropdown-menu dropdown-menu-right" id="userdropdownmenu">
                  {/* <div className="nav-item-div">
                      <strong>
                        <Trans>Points</Trans> {user.pointBalance}
                      </strong>
                    </div> */}
                  {/*[START:MYALLACCESS]*/}
                  {/* <li className="nav-item">
                      <a className="dr-nav-link" id="href-all-my-access-welcome" href="/">
                        {t("All My-Access")}
                      </a>
                    </li> */}
                  {/*[END:MYALLACCESS]*/}

                  {/*[START:PROFILE]*/}
                  <li className="nav-item">
                    <Link className="dr-nav-link" id="href-profile" to="/update-profile" onClick={() => pushDataLayer('update_profile', '/update-profile')}>
                      <span className="material-icons">contact_page</span> {t('Update Profile')}
                    </Link>
                  </li>
                  {/*[END:PROFILE]*/}
                  {/*[START:POINTSHISTORY]*/}
                  <li className="nav-item">
                    <Link className="dr-nav-link" id="href-point-history" to="/points-history" onClick={() => pushDataLayer('point_history', '/points-history')}>
                      <span className="material-icons">timeline</span> {t('Point History')}
                    </Link>
                  </li>
                  {/*[END:POINTSHISTORY]*/}

                  {/*[START:MYREWARDS]*/}
                  <li className="nav-item">
                    <Link className="dr-nav-link" id="href-my-rewards" to="/my-rewards" onClick={() => pushDataLayer('my_rewards', '/my-rewards')}>
                      <span className="material-icons">card_giftcard</span> {t('My Rewards')}
                    </Link>
                  </li>
                  {/*[END:MYREWARDS]*/}
                  {/*[START:PROFILE]*/}
                  <li className="nav-item">
                    <Link className="dr-nav-link" id="program-perks" to="/program-perks" onClick={() => pushDataLayer('program_perks', '/program-perks')}>
                      <span className="material-icons">emoji_events</span> Program Perks
                    </Link>
                  </li>
                  {/*[END:PROFILE]*/}
                  {/*[START:PROFILE]*/}
                  <li className="nav-item">
                    <Link className="dr-nav-link" id="exciting-changes" to="/exciting-changes" onClick={() => pushDataLayer('exciting_changes', '/exciting-changes')}>
                      <span className="material-icons">track_changes</span> Exciting Changes
                    </Link>
                  </li>
                  {/*[END:PROFILE]*/}
                  {/*[START:SUPPORT]*/}
                  <li className="nav-item">
                    <Link className="dr-nav-link" id="href-support" to="/support" onClick={() => pushDataLayer('support', '/support')}>
                      <span className="material-icons">support</span> {t('Support')}
                    </Link>
                  </li>
                  {/*[END:SUPPORT]*/}

                  {/*[START:HELPDESK]*/}
                  {/* <li className="nav-item">
                      <Link className="dr-nav-link" id="href-help-desk" to="/help-desk">
                        {t("Help Desk")}
                      </Link>
                    </li> */}
                  {/*[END:HELPDESK]*/}

                  {/*[START:CONNECTMOVIESANYWHERE]*/}
                  {/* <li className="nav-item">
                      <a className="dr-nav-link" id="href-connect-movie-anyhere" href="/">
                        {t("Connect Movie ANYHERE")}
                      </a>
                    </li> */}
                  {/*[END:CONNECTMOVIESANYWHERE]*/}
                  {/*[START:LOGOUT]*/}
                  <li className="nav-item">
                    <Logout />
                  </li>
                  {/*[END:LOGOUT]*/}
                </ul>
              </div>
            </div>
          </div>
        </li>
      ) : (
        <></>
      )}

      {user.accessToken ? (
        <li className="nav-item notification-icon-btn">
          {/* <Link id="href-notification" className="nav-link" to={'#'} data-toggle="tooltip" data-placement="bottom" title={t('Notification Center')} onClick={e => setShowNotification(!showNotification)}>
            <div className="menu-notification-icon">
              <span className="badge">{notificationList.filter(v => !v.isRead).length}</span>
              <i className="material-icons">add_alert</i>
            </div>
          </Link> */}

          <div className="dropdown usernotification" id="usernotificationdropdown">
            {/* {t("Welcome")} {user.name ? user.name : user.username}!{" "} */}
            <button id="href-profile" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <div className="menu-notification-icon">
                <span className="badge">{notificationList.filter(v => !v.isRead).length}</span>
                <i className="material-icons">add_alert</i>
              </div>
            </button>
            <div className="notification-wrap dropdown-menu dropdown-menu-right" id="usernotificationdropdown">
              <div className="notification-panel">
                <h5>0 {t('New Notification')}</h5>
                <ul className="noti-list">
                  <NotificationCenter showNotification={showNotification} parentCall={setShowNotification} />
                </ul>
              </div>
            </div>
          </div>
        </li>
      ) : (
        ''
      )}
    </Fragment>
  );
};

export default TopRight;
